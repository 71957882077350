function dispositivo() {
    let dispositivo = navigator.userAgent.toLowerCase();

    if (dispositivo.search(/iphone|ipod|ipad|android/) == -1) {
        $('.batch').popover({
            animation: true,
            placement: 'top',
            template: '<div class="popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
            trigger: 'hover',
            //trigger: 'click',
            html: true,
            container: 'body'
        });
    } else {
        $('.batch').popover({
            animation: true,
            placement: 'top',
            template: '<div class="popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
            trigger: 'hover',
            //trigger: 'click',
            html: true,
            container: 'body'
        });
    }
}

function moneda(number, decimals, dec_point, thousands_sep) {

    number = (number + '')
        .replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function(n, prec) {
            var k = Math.pow(10, prec);
            return '' + (Math.round(n * k) / k)
                .toFixed(prec);
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n))
        .split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '')
        .length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1)
            .join('0');
    }
    return s.join(dec);
}

function preparar_envio() {

    var form = $('#frmContactForm, #infoLote');
    var submit = $('#submitCotizacion');
    var alertx = $('.successMsgCotizador');

    form.on('submit', function (e) {
        e.preventDefault();

        $('#frmContactForm .error').remove();

        form.find('#selectEnganche option:disabled').attr('disabled',false);
        form.find('#selectEnganche').attr('disabled',false);

        var hasError = false;

        $('.required').each(function () {
            if (jQuery.trim($(this).val()) === '') {
                $(this).parent().append('<span class="error"><i class="fa fa-exclamation-triangle"></i></span>');
                hasError = true;
            } else if ($(this).hasClass('email')) {
                var emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!emailReg.test(jQuery.trim($(this).val()))) {
                    $(this).parent().append('<span class="error"><i class="fa fa-exclamation-triangle"></i></span>');
                    hasError = true;
                }
            }
        });

        if (!hasError) {
            $('#overlayc, #PleaseWaitc').show();
            $.ajax({
                type: "POST",
                url: "Class/cotizador_lotes.php",
                dataType: "json",
                data: form.serialize(),
                success: function(data) {
                    //console.log(data);
                    $('#overlayc, #PleaseWaitc').hide();

                    if(data.status == "success"){
                        $('#modalLote').modal('toggle');
                        $('.successMsgCotizador').html(data.message);
                        grecaptcha.reset();

                    } else {
                        $('.successMsgCotizador').html(data.message);
                        grecaptcha.reset();
                    }
                },
                error: function (e) {
                    console.log(e);
                }
            });
        }
    });

    $('#frmContactForm input').focus(function () {
        $('#frmContactForm .error').remove();
    });
    $('#frmContactForm textarea').focus(function () {
        $('#frmContactForm .error').remove();
    });

    $('#modalLotContent').parent().prepend('<div id="overlayc" class="ui-widget-overlayx" style="z-index: 1001; display: none;"></div>');
    $('#modalLotContent').parent().prepend('<div id="PleaseWaitc" class="waitx"><img src="images/ajax-loader.gif" /></div>');
}


function createRecaptcha() {
    grecaptcha.render(
        "recaptchaPopupCotizador",
        {
            sitekey: "6Lcsrg0dAAAAAMto3fny9zaHF7N6kf5qTpA7avEU",
            theme: "light"

        }
    );
}



$(document).ready(function () {
    dispositivo();

    var downpayment;
    var superficieLote;
    var cuota;
    var defsCuota = [0, 12, 18, 24, 36];
    var valCuota;
    var areaVerde;
    var lotesEsquina;
    var listOfPrice = 0;



    $('#modalLote').on('hidden.bs.modal', function (e) {
        $('#overlayc, #PleaseWaitc').hide();
        $(this).removeData('bs.modal');

    });

    $('#modalLote').on('shown.bs.modal', function ({relatedTarget}) {


        var button = $(relatedTarget);
        var modal = $(this);
        modal.find('.modal-content').load(button.data("remote"));


        setTimeout(function() {
            downpayment =  $('#selectEnganche');
            superficieLote = parseFloat($('#inputSuperficie').data('superficie'));
            cuota = $('#selectMensualidades');
            areaVerde = parseFloat($('#lotesAreaVerde').val());
            lotesEsquina = parseFloat($('#lotesEsquina').val());


            downpayment.append('<option value selected="selected" disabled="disabled">Escoge tu enganche</option><option value="17">%17 sin intereses</option>');
            cuota.append('<option value selected="selected" disabled="disabled">Escoge tu mensualidad</option><option value="0">Contado</option><option value="12">12 meses</option><option value="18">18 meses</option><option value="24">24 meses</option><option value="36">36 meses</option>');



            function calculaAll() {
                valCuota = parseFloat(cuota.val());
                var dbPrecioXm2 = $('#allPricesM2').val();
                var jsonPricesDb = JSON.parse(dbPrecioXm2);
                var defPriceXm2 = [];
                var p_m2_contado = parseFloat(jsonPricesDb.m2_contado);
                var p_m2_12 = parseFloat(jsonPricesDb.m2_12);
                var p_m2_18 = parseFloat(jsonPricesDb.m2_18);
                var p_m2_24 = parseFloat(jsonPricesDb.m2_24);
                var p_m2_36 = parseFloat(jsonPricesDb.m2_36);

                defPriceXm2.push(p_m2_contado, p_m2_12, p_m2_18, p_m2_24, p_m2_36);


                if(valCuota === 0){
                    listOfPrice = superficieLote * parseFloat(defPriceXm2[0]);
                    $('#price_lote').text(moneda(listOfPrice,2,'.',','));
                    $('#pricem2_lote').text(parseFloat(defPriceXm2[0]).toFixed(2));
                    downpayment.attr('disabled',true);
                    downpayment.val($("#selectEnganche option:first").val());
                    $('#nameTotalPrice').html('Precio de contado');
                    $("#engancheFinal").html("$"+moneda(listOfPrice,2,'.',',') +" MXN");
                    $("#finalEnganche").val(listOfPrice);
                    $("#mensualidadFinal").html(0);
                    $("#montoMensualFinal").html("$"+moneda(listOfPrice,2,'.',',') +" MXN");
                }else{
                    $.each(defsCuota, function (k, v) {
                        //console.log(v);
                        if (v === valCuota) {
                            listOfPrice = superficieLote * parseFloat(defPriceXm2[k]);
                            //console.log(defPriceXm2[k]);
                            $('#pricem2_lote').text(parseFloat(defPriceXm2[k]).toFixed(2));
                            $('#price_lote').text(moneda(listOfPrice, 2, '.', ','));
                        }
                    });

                    downpayment.attr('disabled',false);
                    $('#nameTotalPrice').html('Monto mensual');
                    var downpay = parseFloat(downpayment.val());
                    var finalencanche = (downpay/100)* listOfPrice;
                    var totalfinal = ((listOfPrice - finalencanche) / valCuota);
                    $("#engancheFinal").html("$"+moneda(finalencanche,2,'.',',') +" MXN");
                    $("#finalEnganche").val(finalencanche);
                    $("#mensualidadFinal").html(valCuota);
                    $("#montoMensualFinal").html("$"+moneda(totalfinal,2,'.',',') +" MXN");
                }


            }


            cuota.on('change', function () {
                calculaAll();
            });

            downpayment.on('change', function () {
                calculaAll();
            });

            createRecaptcha();
            preparar_envio();


        }, 1000);

    });

});
